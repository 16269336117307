import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import './App.css'
import { ClipLoader } from 'react-spinners'

const App = () => {
	const defaultKeywords = 'Bank, Forex, Stock Market'
	const defaultText = 'Forex Bank is the best bank in the market!'

	const [keywords, setKeywords] = useState('')
	const [paragraph, setParagraph] = useState('')
	const [videoUrl, setVideoUrl] = useState('')
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [downloadLink, setDownloadLink] = useState('')
	const videoRef = useRef(null)
	const canvasRef = useRef(null)

	const handleSearch = async () => {
		setLoading(true)
		const key = keywords !== '' ? keywords : defaultKeywords
		const query = key
			.split(',')
			.map((kw) => kw.trim())
			.join('+')
		const apiKey = 'gMtP6lvX0LC50FPCxHZ3yvvROT7XFN2Udmwx8JizeK9Ec0UB4TjRjAQz'
		const url = `https://api.pexels.com/videos/search?query=${query}&per_page=1`

		try {
			const response = await axios.get(url, {
				headers: {
					Authorization: apiKey,
				},
			})

			if (response.data.videos.length > 0) {
				setVideoUrl(response.data.videos[0].video_files[0].link)
				setError('')
				setDownloadLink('')
			} else {
				setVideoUrl('')
				setError('No videos found for the given keywords.')
			}
		} catch (error) {
			setVideoUrl('')
			setError('Error fetching videos. Please try again.')
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}

	const drawVideoFrame = () => {
		if (videoRef.current && canvasRef.current) {
			const context = canvasRef.current.getContext('2d')
			context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
			context.fillStyle = 'rgba(0, 0, 0, 0.5)'
			context.fillRect((canvasRef.current.width - canvasRef.current.width * 0.9) / 2, canvasRef.current.height * 0.5 - 75, canvasRef.current.width * 0.9, 150) // Adjust the rectangle size for larger text
			context.fillStyle = 'white'
			context.font = '36px Arial' // Larger font size
			context.textAlign = 'center'
			const para = paragraph !== '' ? paragraph : defaultText
			context.fillText(para, canvasRef.current.width / 2, canvasRef.current.height / 2 + 18) // Adjust vertical alignment for larger text
		}
	}

	const handleDownload = () => {
		if (canvasRef.current) {
			const url = canvasRef.current.toDataURL('image/png')
			setDownloadLink(url)
		}
	}

	useEffect(() => {
		if (videoUrl) {
			const interval = setInterval(() => {
				drawVideoFrame()
			}, 1000 / 30) // Draw at 30fps
			return () => clearInterval(interval)
		}
	}, [videoUrl])

	return (
		<div id='container'>
			<div className='App'>
				<h1>Video Generator</h1>
				<input type='text' value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder='Topic keywords separated by commas' />
				<textarea value={paragraph} onChange={(e) => setParagraph(e.target.value)} placeholder='Text to display in the video'></textarea>
				<button onClick={handleSearch} disabled={loading}>
					{loading && <ClipLoader size={20} color={'#123abc'} loading={loading} />}
					Generate
				</button>
				<div id='videoContainer'>
					{loading ? (
						<div className='shimmer'></div>
					) : videoUrl ? (
						<>
							<div className='videoWrapper'>
								<video className='backgroundVideo' autoPlay loop muted src={videoUrl} ref={videoRef}></video>
								<canvas className='foregroundCanvas' ref={canvasRef} width='1280' height='720'></canvas>
							</div>
							{/* <button onClick={handleDownload}>Download Frame</button>
						{downloadLink && (
							<a href={downloadLink} download='frame.png'>
								Download Link
							</a>
						)} */}
						</>
					) : error ? (
						<p>{error}</p>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default App
